<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form label-width="100px">
      <div>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="SKU" prop="outboundCode">
              {{ basicInfor.sku }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Style" prop="outboundCode">
              {{ basicInfor.Style }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Color" prop="outboundCode">
              {{ basicInfor.color }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="Size" prop="outboundCode">
              {{ basicInfor.size }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="锁定库存">
              {{ basicInfor.lockQuantity }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
    </el-row>
    <el-form ref="addOutboundForm" :model="form" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="逻辑仓" prop="outboundCode">
              <el-select
                v-model="form.logicWarehouseCode"
                clearable
                :placeholder="$t('page.selectPlaceholder')"
                @change="_getCargoOwnerDetail(1)"
              >
                <el-option
                  v-for="item in warehouseList"
                  :key="item.warehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :class="$i18n.locale" label="货主" prop="outboundCode">
              <el-select
                v-model="form.cargoOwnerCode"
                filterable
                clearable
                placeholder="请选择"
                @change="_getCargoOwnerDetail(1)"
              >
                <el-option
                  v-for="item in cargoOwnerList"
                  :key="item.logicWarehouseCode"
                  :value="item.cargoOwnerCode"
                  :label="item.cargoOwnerName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      show-summary
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="logicWarehouseName" label="逻辑仓库" align="center" />
      <el-table-column prop="cargoOwnerCode" label="货主" align="center" />
      <el-table-column prop="lockQuantity" label="锁定库存" align="center">
        <template slot-scope="scope">
          <el-link type="primary" @click="_getPagedetailsOpern(scope.row)">
            {{ scope.row.lockQuantity }}
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
    <DetailsPopup
      :dialog-visible="dialogVisible"
      :details-popup-loading="detailsPopupLoading"
      :details-popup-list="detailsPopupList"
      :details-popup-obj="detailsPopupObj"
      :details-popup-pager="detailsPopupPager"
      :details-popup-column="detailsPopupColumn"
      :details-popup-base-infor="detailsPopupBaseInfor"
      @getPageList="_getPagedetailsPopup"
      @offDialogVisible="offDialogVisible"
    />
  </div>
</template>
<script>

import { getSkuDetail } from '@/api/inventory-inquiry'
import { getWarehouse, getPageSkuLockInventory } from '@/api/stockin'
import { getCargoOwner } from '@/api/listSelection'
import DetailsPopup from '@/components/DetailsPopup'

export default {
  components: {
    DetailsPopup
  },
  data() {
    return {
      basicInfor: {},
      warehouseList: [],
      tableLoading: false,
      form: {
        logicWarehouseCode: '',
        cargoOwnerCode: ''
      },
      tableData: [],
      pager: {
        size: 100,
        current: 1,
        total: 0
      },
      cargoOwnerList: [],
      detailsPopupList: [],
      detailsPopupObj: {},
      dialogVisible: false,
      detailsPopupLoading: false,
      detailsPopupPager: {
        size: 20,
        current: 1,
        total: 0
      },
      detailsPopupColumn: [{
        title: '锁定库存',
        key: 'pairs'
      }],
      detailsPopupBaseInfor: [
        {
          title: '仓库名称',
          key: 'logicWarehouseName'
        }, {
          title: '所属物理仓',
          key: 'sourceWarehouseName'
        }, {
          title: '货主',
          key: 'cargoOwnerCode'
        }, {
          title: '锁定库存',
          key: 'lockQuantity'
        }
      ]
    }
  },
  computed: {
    queryParams() {
      const { sku } = this.$route.query
      return Object.assign({}, this.pager, this.form, { sku, lockQuantity: this.basicInfor.lockQuantity })
    }
  },
  mounted() {
    const { row } = this.$route.query
    this.basicInfor = row && JSON.parse(row) || {}
    this._getCargoOwnerDetail()
    this._queryWarehouseList()
    this._getCargoOwner()
  },
  methods: {
    async _getCargoOwnerDetail(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getSkuDetail(this.queryParams)
        this.pager = pager
        this.tableData = records
      } finally {
        this.tableLoading = false
      }
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    },
    cancle() {
      this.$router.go(-1)
    },
    async _getCargoOwner() {
      const { datas } = await getCargoOwner()
      this.cargoOwnerList = datas
    },
    _getPagedetailsOpern(row) {
      const obj = { pairs: row.lockQuantity, ...row }
      this.detailsPopupList = []
      this.detailsPopupObj = obj
      this.dialogVisible = true
      this.detailsPopupLoading = true
      this._getPagedetailsPopup(obj)
    },
    async _getPagedetailsPopup(row) {
      try {
        const { datas } = await getPageSkuLockInventory(row)
        if (datas && Object.keys(datas).length) {
          const { records, pager } = datas
          this.detailsPopupList = records
          this.detailsPopupPager = pager
        }
        this.detailsPopupLoading = false
      } finally {
        this.detailsPopupLoading = false
      }
    },
    offDialogVisible() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
